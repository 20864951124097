// Colores globales
$mainColor: #1d1d1b; // Color base (más oscuro para que no interfiera con el resto)
$secondaryColor: #dc052b; // Rojo ligeramente atenuado para evitar sobrecargar
$accentColor: #f29300; // Naranja cálido, con mayor saturación
$highlightColor: #ffd100; // Amarillo vibrante, para dar contraste sin ser demasiado brillante
$whiteColor: #ffffff; // Blanco (necesario para textos destacados)
$grayColor: #f0f0f0; // Gris muy claro para mejorar el contraste
$lineColor: #303030; // Gris oscuro para líneas
$lightTextColor: #b6b6b6; // Texto más claro
$buttonHoverColor: #dd052b; // Color de hover de los botones

// Definición de mixins
$width: 799px;

@mixin mobile {
  @media (max-width: #{$width}) {
    @content;
  }
}
