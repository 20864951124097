.libro-reclamaciones {
    padding: 30px;
    background-color: #f9f9f9;
    color: #333;
    font-family: Arial, sans-serif;
    max-width: 1200px;
    margin: 0 auto;
  
    h1 {
      color: #dc052b;
      font-size: 2rem;
      margin-bottom: 1rem;
      text-align: center;
    }
  
    .iframe-container {
      display: flex;
      justify-content: center;
      margin-top: 20px;
  
      iframe {
        width: 100%;
        max-width: 640px; /* Limita el ancho máximo */
        height: 2500px; /* Altura del iframe ajustada */
        border: none;
      }
    }
  
    @media (max-width: 768px) {
      .libro-reclamaciones {
        padding: 15px;
      }
  
      .iframe-container iframe {
        max-width: 100%;
        height: 2800px; /* Altura del iframe ajustada */
      }
    }
  }
  