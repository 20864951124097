@import "./global.scss";
.App {
  display: flex;
  flex-direction: column;
  background-color: $mainColor;
  height: 100vh;  // Asegura que el contenedor ocupe toda la pantalla

  .sections {
    flex-grow: 1;
    overflow-y: auto;  // Permitir desplazamiento solo cuando sea necesario

    /* Personalización del scrollbar */
    &::-webkit-scrollbar {
      width: 8px; 
    }

    &::-webkit-scrollbar-thumb {
      background-color: $accentColor;  // Color del pulgar (thumb)
      border-radius: 10px;
    }

    &::-webkit-scrollbar-track {
      background-color: $grayColor;  // Color del track (fondo)
      border-radius: 10px;
    }
  }
}
