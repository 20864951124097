@import "../../global.scss";

.topbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: linear-gradient(90deg, #dc052b, #f29300);
  padding: 20px; // Ajustado para coincidir con la carta
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  color: #fff;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  flex-wrap: wrap;

  .logo {
    font-size: 28px;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    margin-right: 20px;
    transition: color 0.3s;

    &:hover {
      color: #ffd100;
    }
  }

  .itemContainer {
    display: flex;
    align-items: center;
    color: #fff;
    margin-left: 20px;
    flex-wrap: wrap;

    span {
      margin-right: 10px; // Ajusta el valor según el espacio deseado
    }
  }

  .whatsapp-link {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    &:hover {
      color: #ffd100;
    }
  }

  .icon {
    display: flex;
    align-items: center;
    margin-right: 5px;
    font-size: 20px;
  }

  .itemContainer2 {
    display: flex;
    align-items: center;
    color: #fff;
    margin-left: 20px;
    flex-wrap: wrap;
    white-space: nowrap; /* Evita que el texto se divida en varias líneas */
    overflow: hidden; /* Oculta el texto que sobrepase el contenedor */
    text-overflow: ellipsis; /* Muestra "..." si el texto se corta */
  }

  .itemContainer2 span {
    font-size: 16px;
    margin-left: 2px;
    display: inline-block;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .whatsapp-link {
    display: flex;
    align-items: center;
    text-decoration: none;

    .icon {
      margin-right: 5px;
    }
  }

  /* Estilos para la versión móvil */
  @media (max-width: 768px) {
    .topbar {
      flex-direction: column;
      align-items: flex-start;
      padding: 30px; /* Ajustamos el padding para móviles */
    }

    .logo {
      font-size: 24px;
    }

    .itemContainer {
      margin-top: 5px;
      font-size: 12px; /* Reducimos el tamaño de la fuente */
      justify-content: flex-start;
    }

    .itemContainer2 {
      display: none; /* Eliminamos este contenedor en la versión móvil */
    }

    .itemContainer span {
      font-size: 14px; /* Reducimos el tamaño de la fuente */
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; /* Usa "..." si el texto es más largo */
    }
  }
}
