@import "../../global.scss";
.carta {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 84px 20px;
  background-color: $whiteColor;
  gap: 10px;
  margin-bottom: 0;

  .filterButtons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;

    button {
      padding: 12px 20px;
      font-size: 14px;
      border: none;
      background-color: $grayColor;
      color: $mainColor;
      cursor: pointer;
      border-radius: 8px;
      font-weight: bold;
      transition: all 0.3s ease;

      &:hover {
        background-color: $buttonHoverColor;
        color: $whiteColor;
      }

      &.active {
        background-color: $accentColor;
        color: $whiteColor;
      }
    }

    @include mobile {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      button {
        width: auto;
        font-size: 18px;
        padding: 8px 10px;
      }
    }
  }

  .promocionesContainer {
    width: 100%;
    background-color: $highlightColor;
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 10px;

    h2 {
      text-align: center;
      color: $whiteColor;
      font-size: 24px;
      margin-bottom: 20px;
    }

    .platosContainer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 20px;
    }

    .plato.promocion {
      background-color: #f8f8f8;
      border: 2px solid $highlightColor;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      padding: 15px;
      width: 250px;
      text-align: center;
      transition: transform 0.3s ease-in-out;

      img {
        max-width: 100%;
        max-height: 200px;
        border-radius: 8px;
      }

      h3 {
        color: $lineColor;
      }

      .precio {
        color: $secondaryColor;
        font-weight: bold;
      }

      a {
        display: inline-block;
        margin-top: 10px;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 5px;
        color: $whiteColor;

        &.ordenar {
          background-color: green;
        }

        &.consultar {
          background-color: orange;
        }

        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }

  .platosContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;

    .plato {
      background-color: #f5f5f5;
      border-radius: 8px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      padding: 15px;
      width: 250px;
      text-align: center;
      transition: transform 0.3s ease-in-out;

      img {
        max-width: 100%;
        max-height: 200px;
        border-radius: 8px;
      }

      h3 {
        color: $lineColor;
      }

      .precio {
        color: $secondaryColor;
        font-weight: bold;
      }

      a {
        display: inline-block;
        margin-top: 10px;
        padding: 10px 15px;
        text-decoration: none;
        border-radius: 5px;
        color: $whiteColor;

        &.ordenar {
          background-color: green;
        }

        &.consultar {
          background-color: orange;
        }

        &:hover {
          transform: translateY(-5px);
        }
      }
    }
  }

  .space {
    height: 300px;
  }
}
